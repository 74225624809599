// - - - - - - - - - - - - - - - - -
//   Mixins
// - - - - - - - - - - - - - - - - -
._media-query-screen(@size, @rules) {
  @media only screen and (@size) {
    @rules();
  }
}


// - - - - - - - - - - - - - - - - -
//   Default Breakpoints
// - - - - - - - - - - - - - - - - -
// Desktops and large screens (> 992px)
.ax-breakpoint-l(@rules) {
  ._media-query-screen(~'min-width: @{ax-breakpoint-desktop-min}', @rules);
}

// Tablets and medium screens (> 640px)
.ax-breakpoint-m(@rules) {
  ._media-query-screen(~'min-width: @{ax-breakpoint-tablet-min}', @rules);
}

// Note - there is no breakpoint for mobile and small screens (< 640px).
// Building mobile first means there should be no need for one


// - - - - - - - - - - - - - - - - -
//   Max Breakpoints
// - - - - - - - - - - - - - - - - -
// USE SPARINGLY! There are only a handful of use cases that benefit from this
// Using min breakpoints is always preferred, to encourage mobile-first development
.ax-breakpoint-m-max(@rules) {
  ._media-query-screen(~'max-width: @{ax-breakpoint-tablet-max}', @rules);
}
.ax-breakpoint-s-max(@rules) {
  ._media-query-screen(~'max-width: @{ax-breakpoint-mobile-max}', @rules);
}


// - - - - - - - - - - - - - - - - -
//   Device specific
// - - - - - - - - - - - - - - - - -
// Devices that support retina image. More on proper use of screen resolution media queries:
//   http://www.w3.org/blog/CSS/2012/06/14/unprefix-webkit-device-pixel-ratio/
//   http://css-tricks.com/snippets/css/retina-display-media-query/
.ax-media-query-retina(@rules) {
  @media (-webkit-min-device-pixel-ratio: 1.3),
  (min-resolution: 124.8dpi),
  (min-resolution: 1.3dppx) {
    @rules();
  }
}


// - - - - - - - - - - - - - - - - -
//   Browser specific
// - - - - - - - - - - - - - - - - -
// IE10+
.ax-media-query-ie(@rules) {
  @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @rules();
  }
}

// Edge 12-15 and Edge 16+
.ax-media-query-edge(@rules) {
  @supports (-ms-accelerator:true) or (-ms-ime-align:auto) {
    @rules();
  }
}

// Both IE10+ AND Edge 12-15/Edge 16+
.ax-media-query-ie-edge(@rules) {
  .ax-media-query-ie(@rules);
  .ax-media-query-edge(@rules);
}

// used for hiding or showing things for mobile vs desktop
.ax-breakpoint-m--hidden() { // shown only on mobile
  .ax-breakpoint-m({
    display: none !important;
  });
}
.ax-breakpoint-m--visible() { // shown only on desktop
  display: none !important;
  .ax-breakpoint-m({
    display: block !important;
  });
}
.ax-breakpoint-m--visible-flex() { // this is used where the element is flex, to override the display: none;
  display: none !important;
  .ax-breakpoint-m({
    display: flex !important;
  });
}
